import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Layout, Container } from 'layouts';
import { Header, CardList, SEO } from 'components';
import config from '../../config/site';
import i18n from '../i18n/i18n';

const Tag = ({
  data,
  pageContext: {
    tagName,
    locale,
    defaultSlug,
    supportedLangs,
  }
}) => {

  i18n.changeLanguage(locale)

  /**
   * タグの記事が0件の場合は
   * gatsby-node.jsで概要タグのページを生成しないので
   * ここでは存在チェックはしない
   */
  const { edges } = data.allMarkdownRemark;
  const upperTag = tagName.charAt(0).toUpperCase() + tagName.slice(1);
  return (
    <Layout
      locale={locale}
      defaultSlug={defaultSlug}
      supportedLangs={supportedLangs}
    >
      <SEO
        title={`${upperTag} - ${config[locale].title}`}
        pathname={`/tags/${tagName}`}
        article
      />
      <Header title={upperTag} subTitle={i18n.t("tagPage.subtitle")} />
      <Container type="big">
        <CardList list={edges} />
      </Container>
    </Layout>
  );
};

export default Tag;

Tag.propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.array,
    tagname: PropTypes.string,
  }),
};

/**
 * ロケールでフィルターする
 */
export const query = graphql`
  query($tagName: String!, $locale: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: { tags: { eq: $tagName } } 
        fields: { localePath: { eq: $locale } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          fields {
            localePath
            slug
            defaultSlug
          }
          frontmatter {
            title
            path
            tags
            date
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 640
                  maxHeight: 384
                  quality: 50
                  traceSVG: { color: "#B6BCCF" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
